import React, { useEffect } from 'react'
import { useLocation} from 'react-router-dom'

const PublicHome = () => {
    let { pathname} = useLocation();
    console.log(pathname);
    useEffect(() => {
        let login = `/.auth/login/aad?post_login_redirect_uri=${pathname}`
        window.location.replace(login);
    })
    return (
        <>
        </>
    )
}
export default PublicHome;