import React from 'react';
import Home from './components/Home';
import Login from './components/Login';
import PageNotFound from './components/PageNotFound';
import { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setLoaded] = useState(true)
  useEffect(() => {
    async function getUserInfo() {
      try {
          const response = await fetch('/.auth/me');
          const payload = await response.json();
          const { clientPrincipal } = payload;
          
          if(clientPrincipal){
            setUser(clientPrincipal);
            console.log(`clientPrincipal = ${JSON.stringify(clientPrincipal)}`);
          } 
          
      } catch (error) {
          console.error('No profile could be found ' + error?.message?.toString());
      }
      setLoaded(false)
    };
    getUserInfo()
  }, [setLoaded]);  

  return (
    <>
    { isLoading && (
      <img src={require('./components/loading.gif')} alt="loading" />
    )
    }
    { !isLoading && 
      <Router>
      <Routes>
        <Route path="/:id" element={ user ? <Home user={user}/> : <Login /> } />
        <Route path="/" element={<PageNotFound />} />
      </Routes>
    </Router>
    }
    </>
  )
}

export default App;
