import React, { useEffect }  from 'react'
import CryptoJS, { AES } from "crypto-js";

import {
  useParams
} from "react-router-dom";


function b64encode(str) {
  // PROCESS
  const encodedWord = CryptoJS.enc.Utf8.parse(str); // encodedWord Array object
  const encoded = CryptoJS.enc.Base64.stringify(encodedWord); // string: 'NzUzMjI1NDE='
  return encoded;
}

function Home({user}) {
  let params = useParams();
  let agent = navigator.userAgent;
  
  useEffect(() => {
    const id = params.id;
    const ts = new Date().getTime();
    const apiParams = {user: user.userDetails, id: id, ts: ts, userAgent: agent}
    const ciphertext = b64encode(AES.encrypt(JSON.stringify(apiParams), user.userId).toString());
    const api = `/api/GetMkUrl?key=${ciphertext}`;
    window.location.replace(api);
    },[user, params.id, agent])
  return (
    <>
    <img src={require('./loading.gif')} alt="loading" />
    </>
  )
}
export default Home;
